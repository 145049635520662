import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    nav: {
        width: "100%",
        height: "60px",
        background: "#18379a",
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: "#00e59e",
        paddingLeft: "20px"
    }
}));
const Nav = () => {
    const classes = useStyles({})
    return (
        <div className={classes.nav}>
            <Typography component="h1" variant="h6" noWrap className={classes.title}>
                POS Sales
            </Typography>
        </div>
    )
}

export default Nav
