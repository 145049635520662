import React from "react";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

export const SuccessAlert = () => (
    <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Workflow started
    </Alert>
);

export const ErrorAlert = ({ message }) => (
    <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Failed to start workflow - {message}
    </Alert>
);
