import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Autocomplete } from '@mui/material';

const SERVER_URL = window.location.origin;
const WORKFLOW_URL = `${SERVER_URL}/start-workflow`;
const POS_RESOURCE_URL = `${SERVER_URL}/get-pos-sales-resource`
const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
    },
    addressInput: {
        marginBottom: 5,
    },
    formContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    form: {
        width: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    inlineInputs: {
        display: "flex",
        alignItems: "center",
        marginBottom: "30px"
    },
    inlineInput: {
        margineft: "5px",
        marginRight: "5px"
    }
}));
const Form = ({ setSuccess, setError, setErrorMessage }) => {
    const classes = useStyles({});
    // const [value, setValue] = useState();
    const [bms, setBms] = useState([]);
    const [aaos, setAaos] = useState([])
    const {
        register,
        reset,
        watch,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState();
    const watchBm = watch("businessManager")
    const fetchBusinessManagers = async () => {
        const result = await axios.post(POS_RESOURCE_URL, { resource: "business_manager" })
        const businessManagers = result.data.result.map(b => b.business_manager)
        setBms(businessManagers)
    }
    const fetchAAOs = async (bm) => {
        const result = await axios.post(POS_RESOURCE_URL, { resource: "aao", businessManager: bm })
        const allAaos = result.data.result.map(b => b.aao)
        setAaos(allAaos)
    }
    useEffect(async () => {
        fetchBusinessManagers()
    }, [])
    useEffect(() => {
        setValue("AAO", "")
        fetchAAOs(watchBm)
    }, [watchBm])
    const onSubmit = async (data, e) => {
        console.log(data);
        setLoading(true);
        setSuccess(false);
        setError(false);
        try {
            console.log(WORKFLOW_URL);
            const { data: result } = await axios.post(WORKFLOW_URL, data);
            setLoading(false);
            if (result.status == "success" || result.message == "success") {
                setSuccess(true);
                setError(false);
            } else {
                setError(true);
                setErrorMessage(result.message)
                setSuccess(false)
            }
        } catch (err) {
            setSuccess(false);
            setError(true);
        }
        e.target.reset()
    };
    if (loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress></CircularProgress>
            </div>
        );
    }
    return (
        <div className={classes.formContainer}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <div className={classes.inlineInputs}>
                    <Controller
                        name="businessManager"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                onChange={(event, item) => {
                                    onChange(item);
                                }}
                                disablePortal
                                id="businessManager"
                                options={bms}
                                value={value}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Business Manager" fullWidth required />}
                            />
                        )}
                    ></Controller>
                </div>
                <div className={classes.inlineInputs}>
                    <Controller
                        name="AAO"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                onChange={(event, item) => {
                                    onChange(item);
                                }}
                                disablePortal
                                id="AAO"
                                options={aaos}
                                value={value}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="AAO" required />}
                            />
                        )}
                    ></Controller>
                </div>
                <div className={classes.inlineInputs}>
                    <Controller
                        name="terminalSerial"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                fullWidth
                                type="text"
                                onChange={onChange}
                                value={value}
                                label={"Terminal Serial"}
                                required={true}
                            />
                        )}
                    ></Controller>
                </div>
                <div className={classes.inlineInputs}>
                    <Controller
                        name="customerNo"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                fullWidth
                                type="text"
                                onChange={onChange}
                                value={value}
                                label={"Customer Phone No."}
                                required={true}
                            />
                        )}
                    ></Controller>
                </div>
                <div className={classes.button}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Form;
