import logo from "./logo.svg";
import "./App.css";
import Form from "./Form";
import Nav from "./Nav";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SuccessAlert, ErrorAlert } from "./Alerts";

const useStyles = makeStyles((theme) => ({
  app: {
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    margin: "auto",
    paddingTop: "60px",
    width: "100%",
  },
}));

function App() {
  const classes = useStyles({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")

  return (
    <div>
      <Nav></Nav>
      {success ? (
        <div>
          <SuccessAlert></SuccessAlert>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div>
          <ErrorAlert message={errorMessage}></ErrorAlert>
        </div>
      ) : (
        ""
      )}
      <div className={classes.app}>
        <Form
          className={classes.form}
          setSuccess={setSuccess}
          setError={setError}
          setErrorMessage={setErrorMessage}
        ></Form>
      </div>
    </div>
  );
}

export default App;
